<app-table [data]="datatableRecord" [showCheckboxes]="true" [showmainAction]="false"
	[columns]="datatableColumns" [showTableActions]="false"
	 class="display_none"></app-table>
<div *ngIf="datatableRecord.length === 0; else checkboxList" class="no_data">
    <p>Please Pair your very first screen</p>
    <app-button [data]="{
                                ButtonLabel: 'Add Screens',
                                buttonDisabled: true,
                                type: 'bg_color'
                            }" (click)="gotoscreen()"></app-button>
</div>
<ng-template #checkboxList>
<div *ngFor="let screen of datatableRecord" >
  <div class="checklist ">
    <mat-checkbox [(ngModel)]="screen.isChecked" (change)="toggleRow(screen)" >
      {{ screen.name }}
    </mat-checkbox>
  </div>
</div>
</ng-template>
