<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="body">
          <div class="">

            <ng-content></ng-content>

          </div>
        </div>
      </div>
    </div>
  </div>





