import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Router, NavigationEnd } from "@angular/router";


export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;

}

const ELEMENT_DATA: PeriodicElement[] = [
    { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
    { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
    { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
    { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
    { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
    { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
    { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
    { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
    { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
    { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

@Component({
    selector: 'app-title-bar',
    templateUrl: './title-bar.component.html',
    styleUrls: ['./title-bar.component.scss']
})

export class TitleBarComponent {
    @Output() buttonSubmit = new EventEmitter();
    @Input() data = {
        title: '',
        button_name: '',
    }
    isContentModuel: boolean;
constructor(
  private router: Router,
  ){

  }
  	ngOnInit() {
        this.isContentModuel = this.router.url.includes('content-module');
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isContentModuel = event.url.includes('content-module');
            }
        });
    }
    onClick() {
        this.buttonSubmit.emit();
    }
}
