import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from "src/app/core/service/helper.service";
import { ApiScreenService } from 'src/app/core/service/api-screen.service';
import { SharedService } from 'src/app/core/service/shared.service';
import { Router, NavigationEnd } from "@angular/router";
import { MatDialogRef } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
	selector: 'app-screen-list',
	templateUrl: './screen-list.component.html',
	styleUrls: ['./screen-list.component.scss']
})

export class ScreenListComponent {
  @Output() checkoutButtonSelected = new EventEmitter<boolean>();
  @Output() onCheckoutSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedScreenChange: EventEmitter<any[]> = new EventEmitter<any[]>();
	search_screen_text: string = '';
  isButtonEnabledchecked: boolean = true;
  SelectedScreen: any = [];
	datatableColumns: any = [
		{ name: 'Name', field: 'name' },
		{ name: 'Location', field: 'location' }
	];

	datatableRecord: any = [];

	constructor(
		private apiScreenService: ApiScreenService,
		private helperService: HelperService,
    private router: Router,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private sharedService: SharedService,
    private dialogRef: MatDialogRef<ScreenListComponent>
	) {
		console.log('ScreenListComponent')
	}

	ngOnInit() {
    console.log('test');
		this.getScreenList()
		this.sharedService.update_popup_screen_list.subscribe(
			(search_screens) => {
				let selected_screens = this.helperService.getObjectFromLocatStorage('selected_screens')
				console.log("search_screens: ", search_screens)
				search_screens.forEach((screen: any) => {
					let is_checked = false
					if (selected_screens) {
						is_checked = selected_screens.includes(screen.id) ?? true
					}
					screen.isChecked = is_checked;
					screen.schedule = screen.schedular ?? '---'
					screen.location = screen.location ?? '---'
				})

                this.datatableRecord = search_screens
			}
		)

    this.sharedService.selected_datatable_records_trigger.subscribe(
			(selected_records) => {
				console.log("selected_datatable_records_trigger : ", selected_records)
				if (selected_records == "screen_ids") {

					this.helperService.saveObjectInLocatStorage('selected_screens', this.SelectedScreen);
					let selected_screen_ids = this.helperService.getObjectFromLocatStorage('selected_screens');

					console.log("selected_screen_ids.length: ", selected_screen_ids.length)
					if (selected_screen_ids.length < 1) {
						this.helperService.showToasterNotifications('warning', 'Please select at least One Screen to Continue')
					} else {
						// this.redirectToScheduleListPage()
					}
				} else if (selected_records == "playlist_ids") {
					console.log("selected_datatable_records_trigger playlist_ids: ", selected_records)
				}
			}
		);

	}

	getScreenList() {
		let selected_screens = this.helperService.getObjectFromLocatStorage('selected_screens')

		this.apiScreenService.getSearchScreenList(this.search_screen_text).subscribe((response: any) => {
			let all_screens = response.content;

			all_screens.forEach((screen: any) => {
				let is_checked = false
				if (selected_screens) {
					is_checked = selected_screens.includes(screen.id) ?? true
				}
				screen.isChecked = is_checked;
				screen.name = screen.name ?? '---';
				screen.location = screen.location ?? '---'
			})

			this.datatableRecord = all_screens;
       this.sharedService.updateDatatableRecord(this.datatableRecord);
        if (this.datatableRecord.length === 0) {
            document.body.classList.add('zero-length');
        } else {
            document.body.classList.remove('zero-length');
        }
		})
	}
  //  handleRadioButtonSelection(selected: boolean) {
  //   this.checkoutButtonSelected.emit(selected); // Emit the selected state to the parent component
  // }
  gotoscreen(){
    this.dialogRef.close();
    this.router.navigate(["/admin/dashboard/screens"]);

  }

  toggleRow(row: any) {
    console.log('row', row);
    if(row.isChecked == true){
      this.SelectedScreen.push(row.id);
      this.checkoutButtonSelected.emit(true);
      this.sharedService.updateSelectedScreen(this.SelectedScreen);
    } else {
      var newArray = this.SelectedScreen.filter(function(item) {
          return item !== row.id;
      });
      this.SelectedScreen = newArray;
      this.sharedService.updateSelectedScreen(this.SelectedScreen);
      if (this.SelectedScreen.length===0) {
        this.checkoutButtonSelected.emit(false);
      }
    }

	}
}
