<div class="toaster">
    <!-- <div class="toast-icon flex" [class]="options.toastClass">
      <img src="assets/images/image-gallery/info.png" width="21px" alt="">
    </div> -->
    <div class="toast-details">
      <div>
        <div
          *ngIf="title"
          [class]="options.titleClass"
          [attr.aria-label]="title"
          class="custom-title"
        >
          {{ title }}
        </div>
        <!-- <div
          *ngIf="message && options.enableHtml"
          role="alert"
          aria-live="polite"
          class="custom-message"
          [class]="options.messageClass"
          [innerHTML]="message"
        ></div>
        <div
          *ngIf="message && !options.enableHtml"
          role="alert"
          aria-live="polite"
          class="custom-message"
          [class]="options.messageClass"
          [attr.aria-label]="message"
        >
          {{ message }}
        </div> -->
      </div>
    </div>
    <div class="close-icon">
      <a
        *ngIf="options.closeButton"
        (click)="remove()"
        class="toast-close-button"
      >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z" fill="rgba(243,236,236,1)"></path></svg>
      </a>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
  

