<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" *ngIf="!is_setting" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu" *ngIf="!is_setting">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        y
        [perfectScrollbar]>

        <!-- Top Most level menu -->
        <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
        *ngFor="let sidebarItem of sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
        <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
        <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
        [ngClass]="{ 'menu-top': true, 'active-link': isActive(sidebarItem), 'hovered-link': isHovered(sidebarItem) }"
        (click)="callLevel1Toggle($event, sidebarItem.moduleName)"
        (mouseenter)="hoveredItem = sidebarItem" (mouseleave)="hoveredItem = null">
        <img [src]="getIconSrc(sidebarItem)" alt="Image">
        <span class="hide-menu">{{sidebarItem.title | translate}}</span>
        <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
      </a>
          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                {{sidebarSubItem.title | translate}}
              </a>
              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                    (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                    [ngClass]="[sidebarSubsubItem.class]">
                    {{sidebarSubsubItem.title | translate}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <!-- <li>
          <a class="" (click)="logout()" class="menu-top">
            <i class="material-icons-two-tone">power_settings_new</i>
            <span class="hide-menu">Logout
            </span>
          </a>
        </li> -->

      </ul>
      <div class="setting_sidebar" *ngIf="role != 'USER'">
        <a class="help_support" href="https://support.digitalsigns.ai/"> <img src="assets/images/sidebar/help_icon.svg"> <span>Help & Support</span></a>
        <a (click)="is_setting = true" class="menu-top setting bar_setting sett">
          <span class="hide-menu">Settings
          </span>
          <span class="arrow">
            <i class="material-icons-two-tone">arrow_forward</i>


          </span>

        </a>

      </div>

      <div class="setting_sidebar"  *ngIf="role == 'USER'">
        <a class="" (click)="logout()" class="menu-top loga_">
          <img src="assets/images/sidebar/power-off.png" alt="Active Image " class="logt">
          <span class="hide-menu">Logout
          </span>
        </a>
      </div>
    </div>
    <!-- #Menu -->

  </aside>
  <!-- #END# Left Sidebar -->
  <aside id="leftsidebar" *ngIf="is_setting" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu" >
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        y
        [perfectScrollbar]>

        <!-- Top Most level menu -->
        <!-- <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
          *ngFor="let sidebarItem of settting_sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
          <div class="header" *ngIf="sidebarItem.groupTitle === true">   <span (click)="is_setting = false" class="arrow_back">
            <i class="material-icons-two-tone">arrow_back</i>


          </span> {{sidebarItem.title | translate}}</div>
          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
            [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
            <img *ngIf="'active-link' === (routerLinkActive | async)?.toString()" [src]="sidebarItem.icon_active" alt="Active Image">
            <img *ngIf="'active-link' !== (routerLinkActive | async)?.toString()" [src]="sidebarItem.icon" alt="Inactive Image">
            <span class="hide-menu">{{sidebarItem.title | translate}}
            </span>
            <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
          </a> -->

          <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
          *ngFor="let sidebarItem of settting_sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
          <div class="header" *ngIf="sidebarItem.groupTitle === true">
            <span (click)="is_setting = false" class="arrow_back">
              <i class="material-icons-two-tone">arrow_back</i>


            </span> {{sidebarItem.title | translate}}</div>
          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
          [ngClass]="{ 'menu-top': true, 'active-link': isActive(sidebarItem), 'hovered-link': isHovered(sidebarItem) }"
          (click)="callLevel1Toggle($event, sidebarItem.moduleName)"
          (mouseenter)="hoveredItem = sidebarItem" (mouseleave)="hoveredItem = null">
          <img [src]="getIconSrc(sidebarItem)" alt="Image">
          <span class="hide-menu">{{sidebarItem.title | translate}}</span>
          <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
        </a>


          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                {{sidebarSubItem.title | translate}}
              </a>
              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                    (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                    [ngClass]="[sidebarSubsubItem.class]">
                    {{sidebarSubsubItem.title | translate}}
                  </a>x
                </li>
              </ul>
            </li>
          </ul>
        </li>

      </ul>
      <div class="setting_sidebar">
        <a class="" (click)="logout()" class="menu-top loga_">
          <img src="assets/images/sidebar/logout.png" alt="Active Image " class="logt">
          <span class="hide-menu">Logout
          </span>
        </a>
      </div>
    </div>
    <!-- #Menu -->

  </aside>
</div>
