import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
    // Admin Modules
  {
    path: "/admin/dashboard/main",
    title: "MENUITEMS.SIDEBAR.dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/dashboard_grey.svg",
    icon_active: "assets/images/sidebar/dashboard_white.svg",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "https://editor.digitalsigns.ai/",
    title: "DSEditor",
    moduleName: "dseditor",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/ds-editor-grey.svg",
    icon_active: "assets/images/sidebar/ds-editor_white.svg",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  ,{
    path: "/admin/dashboard/content-module",
    title: "File Manager",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/file_manager_grey.svg",
    icon_active: "assets/images/sidebar/file_manager_white.svg",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/admin/dashboard/playlist",
    title: "MENUITEMS.SIDEBAR.playlists",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/playlist_grey.svg",
    icon_active: "assets/images/sidebar/playlist_white.svg",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/admin/dashboard/templates",
    title: "Templates",
    moduleName: "templates",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/templat-grey.svg",
    icon_active: "assets/images/sidebar/templat-grey_white.svg",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  ,
  {
    path: "/admin/dashboard/schedules",
    title: "Schedules",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/calendar-grey.svg",
    icon_active: "assets/images/sidebar/calendar-white.svg",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  ,
  {
    path: "/admin/dashboard/screens",
    title: "Screens",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/screen_grey.svg",
    icon_active: "assets/images/sidebar/screen_white.svg",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  }
 ];


 export const Setting_ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "Settings",
    moduleName: "",
    iconType: "",
    icon: "assets/images/sidebar/location.png",
    icon_active: "assets/images/sidebar/location_s.png",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },

  // Admin Modules
  {
    path: "/usermanagement/location",
    title: "MENUITEMS.SIDEBAR.location",
    moduleName: "dashboard",
    iconType: "",
    class: "",
    icon: "assets/images/sidebar/location.png",
    icon_active: "assets/images/sidebar/location_s.png",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },

  ,{
    path: "/usermanagement/users",
    title: "MENUITEMS.SIDEBAR.users",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/user.png",
    icon_active: "assets/images/sidebar/user_s.png",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/admin/dashboard/main1rr",
    title: "MENUITEMS.SIDEBAR.devices",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/devices.png",
    icon_active: "assets/images/sidebar/devices_s.png",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  ,
  {
    path: "/admin/dashboard/main1",
    title: "MENUITEMS.SIDEBAR.order_devices",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/checkout.png",
    icon_active: "assets/images/sidebar/checkout_s.png",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "n/dashboard/",
    title: "MENUITEMS.SIDEBAR.subscription",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assets/images/sidebar/renew.png",
    icon_active: "assets/images/sidebar/renew_s.png",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },

 ];
